import React from "react";
import WhyCloudLabProductivityCloud from "components/WhyCloudLabsTables/productivity/productivity-cloud";
import WhyCloudLabProductivityTraditional from "components/WhyCloudLabsTables/productivity/productivity-traditional";
import pageImage from "images/HomepageLogo.png";
import {
  FullBackground,
  Layout,
  SEO,
  WhyCloudLabsInPageNav,
  WhyCloudLabsNav,
} from "components";
import { graphql } from "gatsby";

const WhyCloudLabProductivityPage = ({ data, location }) => {
  const title = "Execute Multiple Experiments in Parallel";
  const description =
    "Spend your time on scientific inquiry, not finding samples and ordering materials";
  return (
    <FullBackground gradient>
      <WhyCloudLabsNav section={location && location.pathname} />
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <WhyCloudLabsInPageNav
          data={data.inPageNav.traditional}
          kind={"traditional"}
          tab={location && location.hash}
        >
          <WhyCloudLabProductivityTraditional data={data.inTable.traditional} />
        </WhyCloudLabsInPageNav>
        <WhyCloudLabsInPageNav
          data={data.inPageNav.cloud}
          kind={"cloud"}
          tab={location && location.hash}
        >
          <WhyCloudLabProductivityCloud data={data.inTable.cloud} />
        </WhyCloudLabsInPageNav>
      </Layout>
    </FullBackground>
  );
};

export default WhyCloudLabProductivityPage;

export const query = graphql`
  query WhyCloudLabProductivityPage {
    inPageNav: whyCloudLabsJson(section: { eq: "productivity" }) {
      traditional {
        tabRoutes
        title
        prevButton {
          text
          route
        }
        nextButton {
          text
          route
        }
      }
      cloud {
        tabRoutes
        title
        prevButton {
          text
          route
        }
        nextButton {
          text
          route
        }
      }
    }
    inTable: whyCloudLabsJson(section: { eq: "productivity" }) {
      traditional {
        content {
          text
          image
          list {
            text
          }
        }
      }
      cloud {
        content {
          header
          text
          image
        }
      }
    }
  }
`;
