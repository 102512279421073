import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { BulletList } from "../components";
import { device } from "utils";

const WhyCloudLabProductivityCloud = ({ data }) => {
  const {
    content: [row1Content, row2Content, row3Content, row4Content, row5Content],
  } = data;
  return (
    <S.Table>
      <S.Row1>
        <S.Image
          src={row1Content[0].image}
          loading="lazy"
          alt="productivity-cloud-banner"
          title="Science is in the Driver's Seat"
        />
      </S.Row1>
      <S.Row2>
        <S.Row2Header>{row2Content[0].header}</S.Row2Header>
        <S.BulletsNode>
          {BulletList({
            data: row2Content,
            level: 0,
          })}
        </S.BulletsNode>
      </S.Row2>
      <S.Row3>
        <S.BulletsNode>
          {BulletList({
            data: row3Content,
            level: 0,
          })}
        </S.BulletsNode>
        <S.Row3Header>{row3Content[0].header}</S.Row3Header>
      </S.Row3>
      <S.Row4>
        <S.Row4Header>{row4Content[0].header}</S.Row4Header>
        <S.BulletsNode>
          {BulletList({
            data: row4Content,
            level: 0,
          })}
        </S.BulletsNode>
      </S.Row4>
      <S.Row5>
        <S.BulletsNode className="row5">
          {BulletList({
            data: row5Content,
            level: 0,
          })}
        </S.BulletsNode>
        <S.Row5Header>{row5Content[0].header}</S.Row5Header>
        <S.FooterImage
          src={row5Content[0].image}
          loading="lazy"
          alt="cloud-scientist"
          title="Cloud Scientist"
        />
      </S.Row5>
    </S.Table>
  );
};

WhyCloudLabProductivityCloud.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.array.isRequired,
  }),
};
WhyCloudLabProductivityCloud.defaultProps = {};

export default WhyCloudLabProductivityCloud;

const S = {};

S.Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
`;
S.TableHeaders = styled.div`
  background: rgba(0, 0, 0, 0.6);
  color: ${({ theme }) => theme.greenLink};
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
  padding: 3rem;
`;
S.Row1 = styled.div`
  display: flex;
  align-items: center;
`;
S.Row2 = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.md} {
    flex-direction: row;
  }
`;
S.Row3 = styled.div`
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column-reverse;

  @media ${device.md} {
    flex-direction: row;
  }
`;
S.Row4 = styled.div`
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;

  @media ${device.md} {
    flex-direction: row;
  }
`;
S.Row5 = styled.div`
  display: flex;
  flex: 0 0 55rem;
  margin-top: 0.5rem;
  flex-direction: column-reverse;
  position: relative;

  @media ${device.md} {
    flex: 0 0 43rem;
    flex-direction: row;
  }
`;
S.Row2Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 10rem 5.7rem;
    flex: 1;
  }
`;
S.Row3Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 8.3rem 6.1rem;
    flex: 1;
  }
`;
S.Row4Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 5rem 5.7rem;
    flex: 1;
  }
`;
S.Row5Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 6.6rem 10.5rem 0 6rem;
    flex: 2.5;
  }
`;
S.BulletsNode = styled.div`
  color: #b1bac2;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.2rem;
  background: #000000;
  flex: 1.5;
  display: flex;
  align-items: center;
  padding: 3rem 2rem;
  &.row5 {
    align-items: flex-start;
  }

  @media ${device.md} {
    padding: 3rem;
    &.row5 {
      padding-top: 5.5rem;
    }
  }
`;
S.Image = styled.img`
  max-width: 100%;
`;
S.FooterImage = styled.img`
  position: absolute;
  bottom: -10rem;
  right: 1rem;
  max-width: 100%;
`;
