import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { BulletList } from "../components";
import { device } from "utils";

const WhyCloudLabProductivityTraditional = ({ data }) => {
  const {
    content: [row1Content, row2aContent, row2bContent],
  } = data;
  return (
    <S.Table>
      <S.Row1>
        <S.Image
          src={row1Content[0].image}
          loading="lazy"
          alt="productivity-traditional-banner"
          title="Science Can Take a Backseat to Labor"
        />
      </S.Row1>
      <S.Row2>
        <S.Row2aTextNode>
          <S.BulletsNode>
            {BulletList({
              data: row2aContent,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row2aTextNode>
        <S.Row2bTextNode>
          <S.BulletsNode>
            {BulletList({
              data: row2bContent,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row2bTextNode>
        <S.FooterImage
          src={row2bContent[0].image}
          loading="lazy"
          alt="traditional-scientist"
          title="Traditional Scientist"
        />
      </S.Row2>
    </S.Table>
  );
};

WhyCloudLabProductivityTraditional.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.array.isRequired,
  }),
};
WhyCloudLabProductivityTraditional.defaultProps = {};

export default WhyCloudLabProductivityTraditional;

const S = {};

S.Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
`;
S.TableNodes = styled.div`
  background: rgba(0, 0, 0, 0.6);
  padding: 3rem 4rem 3rem 3rem;
  color: #b1bac2;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.2rem;
`;
S.Row1 = styled.div`
  display: flex;
  align-items: center;
`;
S.Row2 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 115rem;
  position: relative;

  @media (min-width: 480px) {
    flex: 0 0 95rem;
  }

  @media ${device.md} {
    flex: 0 0 56.9rem;
    flex-direction: row;
  }
`;
S.Row2aTextNode = styled(S.TableNodes)`
  padding-bottom: 0;

  @media ${device.md} {
    padding-bottom: 3rem;
    flex: 1;
  }
`;
S.Row2bTextNode = styled(S.TableNodes)`
  flex: 1;
  padding-top: 0;

  @media ${device.md} {
    padding-top: 3rem;
    margin-left: 0.5rem;
  }
`;
S.BulletsNode = styled.div`
  > ul {
    margin-bottom: 0;
  }
`;
S.Image = styled.img`
  max-width: 100%;
`;
S.FooterImage = styled.img`
  position: absolute;
  bottom: -7rem;
  right: -2rem;
  max-width: 100%;
`;
